import controller from "./controller.svg";
import Login from "./Login.svg";
import speed from "./speed.svg";
import time from "./time.svg";
import user from "./user.svg";
import weather from "./weather.svg";
import location from "./location.svg";
import right_circle from "./right_circle.svg"
import left_circle from "./left_circle.svg"

export const svgs = {
  controller,
  Login,
  speed,
  time,
  user,
  weather,
  location,
  left_circle,
  right_circle
};
