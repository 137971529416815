import {
  configureStore,
  combineReducers,
  applyMiddleware,
} from "@reduxjs/toolkit";
import thunk from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";
import {
  robotCommandsReducer,
  robotDetailsReducer,
  xboxReducer
} from "./reducers/robots/robotDetailsReducer";

let initialState = {};

const reducers = combineReducers({
  robotDetails: robotDetailsReducer,
  robotCommands: robotCommandsReducer,
  xboxController : xboxReducer
});

const middleware = [thunk];
const store = configureStore(
  {
    reducer: reducers,
    middleware: [thunk],
    preloadedState: initialState,
  }
  // composeWithDevTools(applyMiddleware(...middleware))
);

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;

export default store;
