export const styles = () => {
  const controlPageStylesBattery = {
    display: "flex",
    border: "1.5px solid white",
    width: "33px",
    height: "18px",
    borderRadius: "4px",
    // paddingInline: "4px",
    paddingBlock: "2px",
    position: "relative",
    alignItems: "center",
  };

  const controlPageStylesOuterPart = {
    height: "11px",
    width: "3.5px",
    background: "white",
    borderTopRightRadius: "100rem",
    borderBottomRightRadius: "100rem",
    position: "absolute",
    left: "31.5px",
  };

  return { controlPageStylesBattery, controlPageStylesOuterPart };
};
