import styled from "styled-components";

const button = styled.div`
  position: fixed;
  background-color: gray;
  border-radius: 30px;
  width: 30px;
  height: 30px;
  cursor: pointer;
  margin-left: 352px;
  margin-bottom: 239px;
`;

const stick = styled.div`
position: fixed;

z-index: 15;
width: 50px;
height: 50px;
background-color: gray;
border: 3px solid black;
border-radius: 40px;
cursor: pointer;

margin-left: 10rem;
}
`;

const dpad = styled.div`
position: fixed;
background-color: gray;
border-radius: 2px;
width: 24px;
height: 24px;
cursor: pointer;
margin-right: 220px;
    margin-bottom: 43px;
}
`;

const leftShoulder = styled.div`
position: fixed;
transform: translateY(0px);
width: 142px;
height: 78px;

`;

const rightShoulder = styled(leftShoulder)`
  transform: scaleX(-1);
`;

export const ReactInputs = styled.div`
  // position: relative;
  // width: 100%;
  // height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Back = styled.div`
  position: fixed;
  width: 37.1rem;
    height: 26.3rem;
  z-index: 10;
  pointer-events: none;
  background-repeat: no-repeat;
  object-fit: cover;
}
`;

export const WaitingMessage = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  line-height: 100%;
  z-index: 30;
  color: white;
  font-size: 3rem;
  background: rgba(0, 0, 0, 0.5);
`;

export const ReactExample = styled.section`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
`;

export const inputs = {
  L: styled(stick)`
  margin-left: -302px;
  margin-bottom: 183px;
}
  `,

  R: styled(stick)`
    margin-left: 156px;
    margin-bottom: 5px;
  `,

  L1: styled(leftShoulder)`
    background-image: url(https://raw.githubusercontent.com/diegodoumecq/joymap/master/assets/L1.png);
        background-repeat: no-repeat;
    object-fit: cover;
    margin-left: -15rem;
    margin-top: -15.5rem
}
  `,

  L2: styled(leftShoulder)`
    background-image: url(https://raw.githubusercontent.com/diegodoumecq/joymap/master/assets/L2.png);
        background-repeat: no-repeat;
    object-fit: cover;
    margin-left: -15rem;
    margin-top: -15.5rem
    
}
  `,

  R1: styled(rightShoulder)`
    background-image: url(https://raw.githubusercontent.com/diegodoumecq/joymap/master/assets/L1.png);
    background-repeat: no-repeat;
    object-fit: cover;
    margin-left: 6rem;
    margin-top: -15.5rem;
  `,

  R2: styled(rightShoulder)`
    background-image: url(https://raw.githubusercontent.com/diegodoumecq/joymap/master/assets/L2.png);
    background-repeat: no-repeat;
    object-fit: cover;
    margin-left: 6rem;
    margin-top: -15.5rem;
  `,

  A: styled(button)`
    transform: translate(-25px, 67.5px);
  `,

  B: styled(button)`
    transform: translate(14.5px, 28px);
  `,

  X: styled(button)`
    transform: translate(-65px, 28px);
  `,

  Y: styled(button)`
    transform: translate(-25px, -12px);
  `,

  dpadLeft: styled(dpad)`
  transform: translate(7px, 24.5px);
    width: 40px;
  `,

  dpadRight: styled(dpad)`
  transform: translate(56px, 24.5px);
    width: 38px;
  `,

  dpadDown: styled(dpad)`
    transform: translate(31px, 49px);
    height: 40px;
  `,

  dpadUp: styled(dpad)`
    transform: translateX(31px);
    height: 40px;
  `,

  start: styled.div`
  position: fixed;
  background-color: gray;
  border-radius: 31px;
  width: 24px;
  height: 24px;
  cursor: pointer;
  margin-left: 84px;
  margin-bottom: 183px;
}
  `,

  select: styled.div`
    position: fixed;
    background-color: gray;
    border-radius: 31px;
    width: 24px;
    height: 24px;
    cursor: pointer;
    margin-bottom: 183px;
    margin-right: 88px;
  `,

  home: styled.div`
    background-color: gray;
    border-radius: 10px;
    width: 40px;
    height: 40px;
    cursor: pointer;
    display: none;
  `,
};
