import { robotsConstants } from "../../constants/robots/robot";
import { cardData } from "../../assets/other-utils";
import axios from "axios";

// Get Details of a Robots
export const getRobotDetails = (id: string) => async (dispatch: any) => {
  try {
    dispatch({
      type: robotsConstants.ROBOTS_DETAILS_REQUEST,
    });

    let robotDetails = {};

    cardData.forEach((element: any) => {
      element.innerCarddata.forEach((ele: any) => {
        if (ele.robotID === id) {
          robotDetails = ele;
        }
      });
    });

    dispatch({
      type: robotsConstants.ROBOTS_DETAILS_SUCCESS,
      payload: robotDetails,
    });
  } catch (error: any) {
    dispatch({
      type: robotsConstants.ROBOTS_DETAILS_FAIL,
      payload: error,
    });
  }
};

// Send Command to the robot
export const sendCommand =
  (robotId: any, token: any, commandType: any, commandToggle: any) =>
  async (dispatch: any) => {
    try {
      const path = `${process.env.REACT_APP_BASE_URL}interrupt/v1/sendHeadlightCommand`;
      const token = localStorage.getItem("token");

      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      };

      let data;

      switch (commandType) {
        case "headlight":
          dispatch({
            type: robotsConstants.ROBOT_HEAD_LIGHT_REQUEST,
          });

          data = await axios.post(
            path,
            {
              robotId: robotId,
              interruptAction: commandToggle
                ? "ENABLE_HEADLIGHT"
                : "DISABLE_HEADLIGHT",
            },
            config
          );

          dispatch({
            type: robotsConstants.ROBOT_HEAD_LIGHT_SUCCESS,
            payload: data,
          });
          break;

        case "ringlight":
          dispatch({
            type: robotsConstants.ROBOT_RING_LIGHT_REQUEST,
          });

          data = await axios.post(
            path,
            {
              robotId: robotId,
              interruptAction: commandToggle
                ? "ENABLE_RINGLIGHT"
                : "DISABLE_RINGLIGHT",
            },
            config
          );

          dispatch({
            type: robotsConstants.ROBOT_RING_LIGHT_SUCCESS,
            payload: data,
          });
          break;

        case "streamclient":
          dispatch({
            type: robotsConstants.ROBOT_STREAM_CLIENT_REQUEST,
          });
          data = await axios.post(
            path,
            {
              robotId: robotId,
              interruptAction: commandToggle
                ? "ENABLE_STREAM_CLIENT"
                : "DISABLE_STREAM_CLIENT",
            },
            config
          );

          dispatch({
            type: robotsConstants.ROBOT_STREAM_CLIENT_SUCCESS,
            payload: data,
          });
          break;

        default:
          break;
      }
    } catch (error: any) {
      switch (commandType) {
        case "headlight":
          dispatch({
            type: robotsConstants.ROBOT_HEAD_LIGHT_FAIL,
            payload: error,
          });
          break;

        case "ringlight":
          dispatch({
            type: robotsConstants.ROBOT_RING_LIGHT_FAIL,
            payload: error,
          });
          break;

        case "streamclient":
          dispatch({
            type: robotsConstants.ROBOT_STREAM_CLIENT_FAIL,
            payload: error,
          });
          break;

        default:
          break;
      }
    }
  };

// Clear Errors
export const clearErrors = () => async (dispatch: any) => {
  dispatch({
    type: robotsConstants.CLEAR_ERRORS,
  });
};

//Update Modified Key
export const updateModifiedKey = (key: string) => async (dispatch: any) => {
  dispatch({
    type: robotsConstants.UPDATE_MODIFIED_KEY,
    payload: key,
  });
};

//Update Modified Axis
export const updateModifiedAxis = (axis: string) => async (dispatch: any) => {
  dispatch({
    type: robotsConstants.UPDATE_MODIFIED_AXIS,
    payload: axis,
  });
};
