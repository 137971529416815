import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeftLong } from "@fortawesome/free-solid-svg-icons";
import { Switch } from "antd";
import {
    faCirclePause,
    faCirclePlay,
  } from "@fortawesome/free-regular-svg-icons";
import { faSignal ,faEllipsisVertical} from "@fortawesome/free-solid-svg-icons";
import * as FaIcons from "react-icons/fa"
import * as AiIcons from "react-icons/ai"
import * as IoIcons from "react-icons/io"
import * as VscIcons from "react-icons/vsc";


export const allIcons = {
    FontAwesomeIcon,
    faArrowLeftLong,
    Switch,
    faCirclePause,
    faCirclePlay,
    faSignal,
    faEllipsisVertical,
    FaIcons,
    AiIcons,
    IoIcons,
    VscIcons
}

