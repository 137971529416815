import React from "react";
import { NavLink } from "react-router-dom";

const RobotId = ({ id, allIcons }: any) => {
  return (
    <>
      <div className="robot-controll-id">
        <div className="back-button">
          <NavLink
            to="/"
            style={{
              textDecoration: "none",
              margin: "0px",
              color: "black"
            }}
          >
            <allIcons.FontAwesomeIcon icon={allIcons.faArrowLeftLong} />
          </NavLink>
        </div>
        <div className="robo-id">
          <h2 style={{ font: "normal normal bold 14px/16px Poppins" }}>{id}</h2>
        </div>
      </div>
    </>
  );
};

export default RobotId;
