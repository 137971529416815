import Cards from "./cards/Cards";
import Navbar from "./navbar/Navbar";
import CustomCircle from "./CustomCircle/CustomCircle";
import Battery from "./battery/Battery";
import RobotId from "../components/robot-Id/RobotId";
import ControlBtn from "../components/controlBtn/ControlBtn";
import Cameras from "../components/cameras/Cameras";
import Dropdown from "./dropdown/Dropdown";
import CardOuterCom from "./cards/card-components/CardOuterCom";
import CardItems from "./cards/card-components/CardItems";
import RobotStatusBar from "../components/statusBar/RobotStatusBar";
import StreamDeck from "./Stream Deck/StreamDeck";
import Form from "./Form/Form";
import {Root} from "./Joystick/index";
import SidebarMap from "../components/sidebar/SidebarMap";
import SidebarTeleop from "../components/sidebar/SidebarTeleop";
import KeyBoardBtn from "./keyboard/KeyBoardBtn";
import PsJoystick from "./playstation/PsJoystick";


export const components = {
  Cards,
  Navbar,
  CustomCircle,
  Battery,
  RobotId,
  ControlBtn,
  Cameras,
  Dropdown,
  CardOuterCom,
  CardItems,
  RobotStatusBar,
  StreamDeck,
  Form,
  Root,
  SidebarTeleop,
  SidebarMap,
  KeyBoardBtn,
  PsJoystick,
};
