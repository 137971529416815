import React, { useEffect, useCallback, useState } from "react";
import { render } from "react-dom";
import { createJoymap, createQueryModule, QueryModule } from "joymap";
import { map } from "lodash/fp";
import ColorHash from "color-hash";
import tinycolor from "tinycolor2";

import Gamepad from "./GamePad";


interface Player {
  name: string;
  module: QueryModule;
  color: string;
}

const joymap = createJoymap();
const colorHash = new ColorHash({
  saturation: [0.1, 0.7, 0.8],
  lightness: 0.5,
});

const names = ["James"];

// Create a list of players to render
const players: Player[] = names.map((name) => {
  const module = createQueryModule();
  joymap.addModule(module);

  return {
    name,
    module,
    color: colorHash.hex(name),
  };
});

// Invert both of the first player's sticks
// players[0].module.invertSticks([true, true], 'L', 'R');

export const Root = () => {
  const [state, updateState] = useState({});
  const forceUpdate = useCallback(() => updateState({}), []);

  useEffect(() => {
    joymap.setOnPoll(forceUpdate);
    joymap.start();

    return () => {
      joymap.stop();
    };
  }, []);

  return (
    <>
      {map(
        ({ module, name, color }) => (
          <Gamepad
            key={name}
            name={name}
            module={module}
            backgroundColor={color}
            pressedColor={`rgb(41 255 2)`}
          >
          </Gamepad>
        ),
        players
      )}
    </>
  );
};
