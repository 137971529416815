import { useEffect, useCallback, useState } from "react";
import { useParams } from "react-router-dom";
import { components } from "../../components";

//Joymap
import { createJoymap, createQueryModule, QueryModule } from "joymap";
import { map } from "lodash/fp";
import ColorHash from "color-hash";
import { MainStream } from "../../components/cameras/main";
import {
  useAppDispatch,
  useAppSelector,
  useControllScreenData,
} from "../../hooks/Hooks";
import { getRobotDetails } from "../../actions/robots/robot";
import "./ControlScreen.css";

import { Box, Modal, Typography } from "@mui/material";

const names = ["James"];

const joymap = createJoymap();
const colorHash = new ColorHash({
  saturation: [0.1, 0.7, 0.8],
  lightness: 0.5,
});

interface Player {
  // name: string;
  module: QueryModule;
  color: string;
}
const players: Player[] = names.map((name) => {
  const module = createQueryModule();
  joymap.addModule(module);

  return {
    name,
    module,
    color: colorHash.hex(name),
  };
});

const ControlScreen = () => {
  const [conlock,setConLock] = useState(false)
  const [state, updateState] = useState({});

  const dispatch = useAppDispatch();

  const { loading, robot, error } = useAppSelector(
    (state: any) => state?.robotDetails
  );

  const forceUpdate = useCallback(() => updateState({}), []);

  const {
    sidebar,
    setSideBar,
    showMinimizeBtn,
    setShowMinimizeButton,
    unlock,
    unlockController,
    id,
  } = useControllScreenData();

  let videoEl = document.getElementById("stream");

  useEffect(() => {
    dispatch(getRobotDetails(id));
    if (error) {
      // dispatch();
    }
    MainStream();
    joymap.setOnPoll(forceUpdate);
    joymap.start();

    return () => {
      joymap.stop();
    };
  }, []);

  const landscapePopupStyle = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };

  const lock = (conlock : any) =>{
    setConLock(conlock)
  }

  return (
    <>
      <div className="main__controls">
        <components.RobotStatusBar
          unlockController={unlockController}
          robotData={robot}
        />
        <main
          className={`${
            sidebar ? "main__container" : "main__container__toggle"
          }`}
        >
          <components.SidebarMap
            sidebar={sidebar}
            setSideBar={setSideBar}
            showMinimizeBtn={showMinimizeBtn}
            setShowMinimizeButton={setShowMinimizeButton}
          />
          <components.SidebarTeleop
            robotData={robot}
            unlockController={unlockController}
            sidebar={sidebar}
            showMinimizeBtn={showMinimizeBtn}
            lock={lock}
          />
          <components.StreamDeck robotData={robot} />
        </main>
        {map(
          ({ module, color }: any, index: any) => (
            <div
              style={{
                position: "absolute",
                zIndex: "100",
                bottom: "0rem",
                right: "0rem",
                display: unlock ? "" : "none",
              }}
              key={"joystick__controller__" + index}
            >
              <components.ControlBtn
                key={"joystick__" + index}
                module={module}
                backgroundColor={color}
                pressedColor={`rgb(41 255 2)`}
                conlock={conlock}
              ></components.ControlBtn>
            </div>
          ),
          players
        )}
      </div>
      <Modal
        open
        className="landscape__popup__container"
        // onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={landscapePopupStyle}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Change Orientation
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            Please rotate your device.
          </Typography> 
        </Box>
      </Modal>
    </>
  );
};

export default ControlScreen;
