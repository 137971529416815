import { MainStream } from "./main";

const Cameras = ({ allVideos }: any) => {
  return (
    <>
      <div className="front__main__camera">
        <video className="cam-video" id="stream" src="" autoPlay muted loop />
      </div>
    </>
  );
};

export default Cameras;
