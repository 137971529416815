import { useState, useEffect, useCallback } from "react";
import { getWeatherAndTimeData } from "../api";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import type { RootState, AppDispatch } from "../store";
import { useParams } from "react-router-dom";
import SignalingChannel from "../lib/signaling-channel";

export const useStatusBarLiveData = (city: any) => {
  const [data, setData] = useState({
    temp: 20,
    time: "2023-05-04 11:37",
    condition: "Sunny",
  });

  const getApiData = useCallback(async (city: any) => {
    const apiData: any = await getWeatherAndTimeData(city);
    setData({
      temp: apiData?.data?.current?.temp_c,
      time: apiData?.data?.location?.localtime,
      condition: apiData?.data?.current?.condition?.text,
    });
  }, []);

  useEffect(() => {
    setInterval(() => {
      getApiData(city);
    }, 60000);
  }, [getApiData, city]);

  return { data };
};

// export const useChecked = () => {
//   const [checked, setChecked] = useState(false);

//   const handleSwtichClick = () => {
//     !checked ? setChecked(true) : setChecked(false);
//     return checked;
//   };

//   return { checked, handleSwtichClick };
// };

export const useCurrentUser = (robotData: any) => {
  const [curUser, setCurUser] = useState("");

  const currentUser = () => {
    robotData.operators &&
      robotData.operators.some((element: any) => {
        if (element.user === true) {
          setCurUser(element.userName);
        } else {
          return null;
        }
      });
  };

  return { curUser, currentUser };
};


export const useLights = () => {
  const [ringLightChecked, setRingLightChecked] = useState(false);
  const handleRingLight = () => {
    setRingLightChecked(!ringLightChecked);
  };

  const [headLightChecked, setHeadLightChecked] = useState(false);
  const handleHeadLight = () => {
    setHeadLightChecked(!headLightChecked);
  };

  const [streamClientChecked, setStreamClientChecked] = useState(false);

  const handleStreamClient = () => {
    setStreamClientChecked(!streamClientChecked);
  };
  return {
    ringLightChecked,
    handleRingLight,
    headLightChecked,
    handleHeadLight,
    streamClientChecked,
    handleStreamClient,
  };
};

export const useControllScreenData = () => {
  const [sidebar, setSideBar] = useState(true);
  const [showMinimizeBtn, setShowMinimizeButton] = useState(true);
  const [unlock, setUnlock] = useState(Boolean);

  //Unlocking Controller
  const unlockController = (val: any) => {
    setUnlock(val);
  };

  //fetching the Robot ID
  const params = useParams();
  const id: any = params.id;

  return {
    sidebar,
    setSideBar,
    showMinimizeBtn,
    setShowMinimizeButton,
    unlock,
    unlockController,
    id,
  };
};

export const useChannel = () => {
  const PEER_ID = "teleop1";
  const PEER_TYPE = "admin";
  const SIGNALING_SERVER_URL = "https://joycon.ottonomy.io";
  const TOKEN = "SIGNAL123";
  const Target = "OB02221011";
  const channel = new SignalingChannel(
    PEER_ID,
    PEER_TYPE,
    SIGNALING_SERVER_URL,
    TOKEN
  );


  return {channel,Target}
}

// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
