import { Button } from "@mui/material";
import { DropDown } from "../../components/Form/form-utils/DropDown";
import { components } from "../../components/index";
import "./Config.css";
import { useState } from "react";

const Config = () => {
  const [controllerType, setControllerType] = useState("");

  const comands = [
    "L1",
    "L2",
    "L3",
    "R1",
    "R2",
    "R3",
    "dpadUp",
    "dpadDown",
    "dpadLeft",
    "dpadRight",
    "A",
    "B",
    "X",
    "Y",
    "start",
    "select",
  ];

  const controllerTypes = ["Keyboard", "PS-Controller", "Xbox-Controller"];

  const allControllers = [
    {
      name: "Keyboard",
      main: <components.KeyBoardBtn />,
    },
    {
      name: "Xbox-Controller",
      main: <components.Root />,
    },
    {
      name: "PS-Controller",
      main: <components.PsJoystick />,
    },
  ];

  const getControllerType = (fromChild: any) => {
    setControllerType(fromChild);
  };

  return (
    <>
      <div className="components">
        <div
          className="joystick-div"
          style={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            height: "100vh",
            padding: "10px",
            alignItems: "center",
          }}
        >
          <div
            className="dynamic-dropdown"
            style={{
              display: "flex",
              flexDirection: "row",
              gap: "3rem",
              justifyContent: "center",
              position: "fixed",
              width: "25%",
              marginBottom: "50rem",
            }}
          >
            <DropDown
              inputText="Controller Type"
              comands={controllerTypes}
              getControllerType={getControllerType}
            />
          </div>

          {controllerTypes.map((x: any) => {
            const controller = allControllers.find(
              (controller) => controller.name === x
            );
            return controllerType === x ? controller?.main : null;
          })}
        </div>
        <div className="form-div">
          <components.Form />
        </div>
      </div>
    </>
  );
};

export default Config;
