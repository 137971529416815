// import { env } from "process";
import viewer from "./viewer";

export const MainStream = async () => {
  //need to set env variables
  //   if (process.env.VITE_WHEP_URL) {
  //     throw new Error("Missing environment variables");
  //   }

  const whepUrl = "https://director.millicast.com/api/whep/rGE9Jy/OB02221018";
  // const whepUrl =
  //   "https://director.millicast.com/api/whep/rGE9Jy/testingstream";
  const subscriberToken =
    "2d582d3f630a5bc28f4e55297c24510a6ffc90bf17ed97742860b1d6627b4f1b";
  // const whepUrl = ;
  // const subscriberToken = env.VITE_SUBSCRIBER_TOKEN;
  const whepVideo = document.getElementById("stream");
  if (whepVideo) {
    whepVideo.muted = true;
    whepVideo.controls = true;
    await viewer(whepUrl, whepVideo, subscriberToken);
  }
};
