import { useState, useEffect } from "react";
import { QueryModule } from "joymap";
import { map } from "lodash/fp";
import { images } from "../../assets/images";
import { PadContainer, ReactInputs, inputs, Circle } from "./styles";
import { useChannel } from "../../hooks/Hooks";
import SignalingChannel from "../../lib/signaling-channel";

//--------------Some other settings for Config-------------------
let decimalFixed = 2
let intervalFreq = 50


//----------------------Channel Connection------------
const PEER_ID = "teleop2";
const PEER_TYPE = "admin";
const SIGNALING_SERVER_URL = "https://joycon.ottonomy.io";
const TOKEN = "SIGNAL123";
const Target = "OB02221018";
const channel = new SignalingChannel(
  PEER_ID,
  PEER_TYPE,
  SIGNALING_SERVER_URL,
  TOKEN
);

channel.onMessage = (message) => {
  console.log(message);
};
channel.connect();

//------------------------

const controll_btn_images = [images.left_input, images.right_input];

type InputNames = keyof typeof inputs;

const analogInputs: InputNames[] = ["L", "R"];
const shoulderInputs: InputNames[] = ["L2", "L1", "R2", "R1"];
const digitalInputs: InputNames[] = [
  "dpadUp",
  "dpadDown",
  "dpadLeft",
  "dpadRight",
  "A",
  "B",
  "X",
  "Y",
  "start",
  "select",
  "home",
];

interface GamepadProps {
  backgroundColor: string;
  pressedColor: string;
  module: QueryModule;
  // name: string;
  // children: ReactNode;
  conlock: any;
}

interface ButtonProps {
  inputName: keyof typeof inputs;
  pressedColor: string;
  module: QueryModule;
  // setWaitingFor: (value: string | null) => void;
  setAllButtons: any;
  allButtons: any;
  conlock: any;
}

function Stick({
  inputName,
  pressedColor,
  module,
  // setWaitingFor,
  setAllButtons,
  allButtons,
  conlock,
}: ButtonProps) {
  const [x, y] = module.getStick(inputName).value;
  // console.log(y)

  const { pressed } = module.getButton(`${inputName}3`);
  const StickComponent = inputs[inputName];
  useEffect(() => {
    // let nx=-x

    //Dead-Band Code
    let Dead_Band_X_Max = 0.0;
    let Dead_Band_X_Min = 0.0;
    let Max_V_X = 0.0;
    let Min_V_X = 0.0;
    let Dead_Band_Y_Max = 0.0;
    let Dead_Band_Y_Min = 0.0;
    let Max_V_Y = 0.0;
    let Min_V_Y = 0.0;
    let v_y = 0.0;
    let v_x = 0.0;

    if (inputName === "R") {
      Dead_Band_X_Max = 0.1;
      Dead_Band_X_Min = -0.1;
      Max_V_X = 1.0;
      // Min_V_X = 1.0
      Dead_Band_Y_Max = 0.1;
      Dead_Band_Y_Min = -0.1;
      Max_V_Y = 1.0;
      Min_V_Y = -1.0;
      v_y = 0.0;
      v_x = 0.0;

      if (x >= Dead_Band_X_Max) {
        v_x = Max_V_X * (1.0 / (1.0 - Dead_Band_X_Max)) * (x - Dead_Band_X_Max);
      } else if (x <= Dead_Band_X_Min) {
        v_x = Max_V_X * (1.0 / (1.0 + Dead_Band_X_Min)) * (x - Dead_Band_X_Min);
      } else {
        v_x = 0.0;
      }

      if (y >= Dead_Band_Y_Max) {
        v_y = Max_V_Y * (1.0 / (1.0 - Dead_Band_Y_Max)) * (y - Dead_Band_Y_Max);
      } else if (y <= Dead_Band_Y_Min) {
        v_y = Max_V_Y * (1.0 / (1.0 + Dead_Band_Y_Min)) * (y - Dead_Band_Y_Min);
      } else {
        v_y = 0.0;
      }
    } else if (inputName === "L") {
      Dead_Band_X_Max = 0.1;
      Dead_Band_X_Min = -0.1;
      Max_V_X = 1.0;
      Min_V_X = 1.0;
      Dead_Band_Y_Max = 0.1;
      Dead_Band_Y_Min = -0.1;
      Max_V_Y = 1.0;
      Min_V_Y = 1.0;
      v_y = 0.0;
      v_x = 0.0;

      //Added these conditions after 10/06/2023
      if (x >= Dead_Band_X_Max) {
        v_x = Max_V_X * (1.0 / (1.0 - Dead_Band_X_Max)) * (x - Dead_Band_X_Max);
      } else if (x <= Dead_Band_X_Min) {
        v_x = Max_V_X * (1.0 / (1.0 + Dead_Band_X_Min)) * (x - Dead_Band_X_Min);
      } else {
        v_x = 0.0;
      }

      if (y >= Dead_Band_Y_Max) {
        v_y = Max_V_Y * (1.0 / (1.0 - Dead_Band_Y_Max)) * (y - Dead_Band_Y_Max);
      } else if (y <= Dead_Band_Y_Min) {
        v_y = Max_V_Y * (1.0 / (1.0 + Dead_Band_Y_Min)) * (y - Dead_Band_Y_Min);
      } else {
        v_y = 0.0;
      }
    }

    // console.log(inputName, x, y);
    setAllButtons((allButtons: any) =>
      conlock
        ? {
            ...allButtons,
            axis: {
              ...allButtons.axis,
              [inputName]: {
                ...allButtons.axis[inputName],
                x: -v_x.toFixed(decimalFixed), //Reversed x and y values to negetive for both L and R after 10/06/2023
                y: -v_y.toFixed(decimalFixed),
              },
            },
          }
        : { ...allButtons }
    );

  }, [conlock, inputName, setAllButtons, x, y]);

  useEffect(() => {
    setAllButtons((allButtons: any) =>
      conlock
        ? {
            ...allButtons,
            buttons: { ...allButtons.buttons, [`${inputName}3`]: +pressed },
          }
        : { ...allButtons }
    );
    // channel.send(allButtons);
  }, [conlock, inputName, pressed, setAllButtons]);

  return (
    <StickComponent
      onClick={() => {
        if (module.isConnected()) {
          // module.stickBindOnPress(inputName, () => setWaitingFor(null));
          // setWaitingFor(inputName);
        }
      }}
      style={{
        transform: `translate(${x * 65}px, ${y * 65}px)`,
        backgroundColor: pressed ? pressedColor : "",
      }}
    />
  );
}

function Digital({
  inputName,
  pressedColor,
  module,
  // setWaitingFor,
  setAllButtons,
  allButtons,
  conlock,
}: ButtonProps) {
  const { pressed } = module.getButton(inputName);
  const DigitalComponent = inputs[inputName];

  useEffect(() => {
    setAllButtons((allButtons: any) =>
      conlock
        ? {
            ...allButtons,
            buttons: { ...allButtons.buttons, [inputName]: +pressed },
          }
        : { ...allButtons }
    );
  }, [conlock, inputName, pressed, setAllButtons]);

  return (
    <DigitalComponent
      style={{ backgroundColor: pressed ? pressedColor : "" }}
      onClick={() => {
        if (module.isConnected()) {
          // module.buttonBindOnPress(inputName, () => setWaitingFor(null));
          // setWaitingFor(inputName);
        }
      }}
    />
  );
}

function Shoulder({
  inputName,
  module,
  allButtons,
  setAllButtons,
  conlock,
}: {
  inputName: InputNames;
  module: QueryModule;
  allButtons: any;
  setAllButtons: any;
  conlock: any;
}) {
  const { value } = module.getButton(inputName);
  const ShoulderComponent = inputs[inputName];

  useEffect(() => {
    setAllButtons((allButtons: any) =>
      conlock
        ? {
            ...allButtons,
            buttons: { ...allButtons.buttons, [inputName]: value == 1 ? Math.ceil(value) : 0 },
          }
        : { ...allButtons }
    );

    console.log(value == 1 ? Math.ceil(value) : 0 )
  }, [conlock, inputName, setAllButtons, value]);

  return (
    <div
      style={{
        position: "absolute",
        transform: `translateY(${value * 10}px)`,
        width: "100%",
        height: "100%",
      }}
    >
      <ShoulderComponent />
    </div>
  );
}

export default function ControlBtn({
  backgroundColor,
  pressedColor,
  module,
  conlock,
}: //   name,
// children,
GamepadProps) {
  // const {channel,Target} = useChannel()

  // const [waitingFor, setWaitingFor] = useState<string | null>(null);
  const [allButtons, setAllButtons] = useState({
    buttons: {
      L1: 0,
      L2: 0,
      L3: 0,
      R1: 0,
      R2: 0,
      R3: 0,
      dpadUp: 0,
      dpadDown: 0,
      dpadLeft: 0,
      dpadRight: 0,
      A: 0,
      B: 0,
      X: 0,
      Y: 0,
      start: 0,
      select: 0,
      home: 0,
    },
    axis: {
      L: { x: 0, y: 0 },
      R: { x: 0, y: 0 },
    },
  });


  useEffect(() => {
    // Connecting with server - Controller
    if (conlock) {
      const initialButtons = { buttons: { ...allButtons.buttons }, axis: { ...allButtons.axis } };
      console.log(initialButtons);
      channel.sendTo(Target, initialButtons);
  
      const intervalId = setInterval(() => {
        channel.sendTo(Target, allButtons);
      }, intervalFreq);                                                //This interval value should be dynamic from config
  
      return () => {
        clearInterval(intervalId);
      };
    } else {
      console.log("Joystick is locked");
    }
  }, [conlock, allButtons, channel, Target]);

  return (
    <>
      {map(
        (inputName) => (
          <div style={{ display: "none" }}>
            <Shoulder
              key={inputName}
              inputName={inputName}
              module={module}
              setAllButtons={setAllButtons}
              allButtons={allButtons}
              conlock={conlock}
            />
          </div>
        ),
        shoulderInputs
      )}
      {map(
        (inputName) => (
          <div style={{ display: "none" }}>
            <Digital
              key={inputName}
              inputName={inputName}
              pressedColor={pressedColor}
              module={module}
              // setWaitingFor={setWaitingFor}
              setAllButtons={setAllButtons}
              allButtons={allButtons}
              conlock={conlock}
            />
          </div>
        ),
        digitalInputs
      )}
      <PadContainer
        //   style={{ backgroundColor }}
        disconnected={!module.isConnected()}
      >
        <ReactInputs
          style={{
            width: "360px",
            height: "200px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {analogInputs.map(
            (inputName: any, index: any) => (
              <Circle
                // key={"controller__circle__" + index}
                style={{
                  backgroundImage: `url(${controll_btn_images[index]})`,
                }}
              >
                <Stick
                  key={"controller__stick__" + index}
                  inputName={inputName}
                  pressedColor={pressedColor}
                  module={module}
                  // setWaitingFor={setWaitingFor}
                  setAllButtons={setAllButtons}
                  allButtons={allButtons}
                  conlock={conlock}
                />
              </Circle>
            ),
            analogInputs
          )}
        </ReactInputs>
      </PadContainer>
    </>
  );
}
