import styled from "styled-components";

const button = styled.div`
  position: absolute;
  background-color: gray;
  border-radius: 30px;
  top: 100px;
  right: 45px;
  width: 30px;
  height: 30px;
  cursor: pointer;
`;

const stick = styled.div`
  position: absolute;
  z-index: 15;
  width: 30px;
  height: 30px;
  background-color: #bec4de;
  border-radius: 40px;
  cursor: pointer;
`;

const dpad = styled.div`
  position: absolute;
  background-color: gray;
  border-radius: 10px;
  top: 100px;
  left: 45px;
  width: 30px;
  height: 30px;
  cursor: pointer;
`;

const leftShoulder = styled.div`
  width: 100%;
  height: 100%;
  margin-top: -10px;
`;

const rightShoulder = styled(leftShoulder)`
  transform: scaleX(-1);
`;

export const PadContainer = styled.div<{
  disconnected: boolean;
  
}>`
  // display: flex;
  // flex-direction: row;
  // align-items: center;
  // justify-content: center;
  // height: 100vh;
`;

export const Circle = styled.div`
  /* border: 5px solid rgb(190, 196, 222); */
  /* background-color: rgb(28, 30, 44); */
  /* border-radius: 50rem; */
  height: 151px;
  width: 151px;
  position: relative;
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  background-repeat: no-repeat;
  object-fit: cover;
`;

export const ReactInputs = styled.div`
  overflow: hidden;
  display: flex;
  justify-content: center;
`;

export const ModuleName = styled.span`
  position: absolute;
  top: 70px;
  width: 100%;
  text-align: center;
  z-index: 15;
  font-size: 2rem;
`;

export const Back = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 10;
  background-image: url(https://raw.githubusercontent.com/diegodoumecq/joymap/master/assets/gamepad.png);
  pointer-events: none;
`;

export const WaitingMessage = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  line-height: 100%;
  z-index: 30;
  color: white;
  font-size: 3rem;
  background: rgba(0, 0, 0, 0.5);
`;

export const ReactExample = styled.section`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
`;

export const inputs = {
  L: styled(stick)`
    // left: 35rem;
  `,

  R: styled(stick)`
    // right: 35rem;
  `,

  L1: styled(leftShoulder)`
    background-image: url(https://raw.githubusercontent.com/diegodoumecq/joymap/master/assets/L1.png);
  `,

  L2: styled(leftShoulder)`
    background-image: url(https://raw.githubusercontent.com/diegodoumecq/joymap/master/assets/L2.png);
  `,

  R1: styled(rightShoulder)`
    background-image: url(https://raw.githubusercontent.com/diegodoumecq/joymap/master/assets/L1.png);
  `,

  R2: styled(rightShoulder)`
    background-image: url(https://raw.githubusercontent.com/diegodoumecq/joymap/master/assets/L2.png);
  `,

  A: styled(button)`
    transform: translate(-25px, 49px);
  `,

  B: styled(button)`
    transform: translateY(25px);
  `,

  X: styled(button)`
    transform: translate(-50px, 25px);
  `,

  Y: styled(button)`
    transform: translateX(-25px);
  `,

  dpadLeft: styled(dpad)`
    transform: translateY(30px);
    width: 40px;
  `,

  dpadRight: styled(dpad)`
    transform: translate(55px, 30px);
    width: 40px;
  `,

  dpadDown: styled(dpad)`
    transform: translate(32px, 50px);
    height: 40px;
  `,

  dpadUp: styled(dpad)`
    transform: translateX(30px);
    height: 40px;
  `,

  start: styled.div`
    position: absolute;
    background-color: gray;
    border-radius: 10px;
    top: 106px;
    width: 30px;
    height: 30px;
    right: 170px;
    cursor: pointer;
  `,

  select: styled.div`
    position: absolute;
    background-color: gray;
    border-radius: 10px;
    top: 106px;
    width: 30px;
    height: 30px;
    left: 170px;
    cursor: pointer;
  `,

  home: styled.div`
    position: absolute;
    background-color: gray;
    border-radius: 10px;
    top: 144px;
    width: 40px;
    height: 40px;
    left: 223px;
    cursor: pointer;
  `,
};
