import { useState } from "react";
import { allIcons } from "../../assets/icons";
import "./SidebarMap.css";

const SidebarMap = ({
  sidebar,
  setSideBar,
  showMinimizeBtn,
  setShowMinimizeButton,
}: any) => {
  // const [sidebar, setSideBar] = useState(true);
  const showSidebar = () => {
    minimizeEffect();
    setSideBar(!sidebar);
  };
  // const [showMinimizeBtn, setShowMinimizeButton] = useState(true);

  const minimizeEffect = () => {
    setShowMinimizeButton(false);

    setTimeout(() => {
      setShowMinimizeButton(true);
    }, 2000);
  };

  return (
    <aside
      className="map"
      style={{
        overflow: sidebar ? "hidden" : "overflow",
      }}
    >
      <iframe
        className="robot__map"
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3001156.4288297426!2d-78.01371936852176!3d42.72876761954724!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4ccc4bf0f123a5a9%3A0xddcfc6c1de189567!2sNew%20York%2C%20USA!5e0!3m2!1sen!2sbd!4v1603794290143!5m2!1sen!2sbd"
        frameBorder="0"
        title="robotMap"
        style={
          sidebar
            ? {
                zIndex: "0",
                minHeight: "300px",
                height: "100%",
                border: "0",
                borderRadius: "7px",
                visibility: "visible",
                opacity: 1,
              }
            : {
                zIndex: "1",
                minHeight: "300px",
                height: "100%",
                border: "0",
                borderRadius: "7px",
                visibility: "hidden",
                opacity: 0,
              }
        }
        allowFullScreen={true}
        aria-hidden="false"
        tabIndex={0}
      ></iframe>
      {showMinimizeBtn && (
        <span
          id="minimize__ref"
          className={`sidebar__minimize__btn`}
          onClick={showSidebar}
          style={
            sidebar
              ? {
                  right: "-23px",
                  justifyContent: "start",
                  visibility: "hidden",
                  zIndex: 2,
                }
              : {
                  right: "0px",
                  justifyContent: "end",
                  visibility: "visible",
                  background: "#735AB6",
                  zIndex: 0,
                }
          }
        >
          {sidebar ? (
            <allIcons.VscIcons.VscChevronLeft className="sidebar__minimize__btn__icon" />
          ) : (
            <allIcons.VscIcons.VscChevronRight className="sidebar__minimize__btn__icon" />
          )}
        </span>
      )}
    </aside>
  );
};

export default SidebarMap;
