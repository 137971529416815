import React, { useState } from "react";
import { svgs } from "../../assets/svgs/index";
import "./Cards.css";
import { components } from "../../components";
import { allIcons } from "../../assets/icons";

const Cards = ({cardValues} : any) => {
  
  return (
    <>
      <div className="cards">
       <components.CardOuterCom svgs={svgs} cardValues={cardValues}/>
        <div className="card">
          <components.CardItems svgs={svgs} cardValues={cardValues} allIcons={allIcons} components={components}/>
        </div>
      </div>
    </>
  );
};

export default Cards;
