import { DropDown } from "./form-utils/DropDown";
import { FormSwitch, Switch } from "./form-utils/FormSwitch";
import "./Form.css";
import left from "../../assets/images/left_input.png";
import right from "../../assets/images/right_input.png";
import { FaRegQuestionCircle } from "react-icons/fa";

import { useEffect, useState } from "react";
import { Box, Modal, Button } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenToSquare } from "@fortawesome/free-solid-svg-icons";
import { useAppSelector } from "../../hooks/Hooks";

const handleSubmit = () => {
  console.log("Bruh...");
};

const style = {
  width: "100%",
  transform: "translate(0%, 0%)",
  bgcolor: "background.paper",
  border: "2px solid #000",
  pt: 2,
  px: 4,
  pb: 3,
};

const inputStyle = {
  width: "48px",
  height: "19px",
  padding: "0px",
  fontSize: "18px",
  border: "none",
};

const comands = [
  "L1",
  "L2",
  "L3",
  "R1",
  "R2",
  "R3",
  "dpadUp",
  "dpadDown",
  "dpadLeft",
  "dpadRight",
  "A",
  "B",
  "X",
  "Y",
  "start",
  "select",
];

//for setting up Command using a single key
function ChildModal({ keyPressed, getValueFromChildModal }) {
  const [pressedKey, setPressedKey] = useState("");
  const [open, setOpen] = useState(false);
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = () => {
    setPressedKey(keyPressed); //not setting the xobx button key
    getValueFromChildModal(pressedKey);
    console.log(pressedKey);
  };

  return (
    <>
      <Button onClick={handleOpen}>
        <FontAwesomeIcon
          icon={faPenToSquare}
          style={{ color: "#04ff00", fontSize: "28px" }}
        />
      </Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
      >
        <Box
          sx={{
            ...style,
            width: 200,
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            gap: "1rem",
          }}
        >
          <h2 id="child-modal-title">Press a key</h2>
          <input
            type="text"
            name="fname"
            value={pressedKey}
            style={{ ...inputStyle }}
            onChange={handleChange}
          />
          <Button onClick={handleClose}>Close</Button>
        </Box>
      </Modal>
    </>
  );
}

const Form = () => {
  const { modifiedKey ,modifiedAxis} = useAppSelector((state) => state.xboxController);
  console.log(modifiedAxis?.L?.x)
  // console.log(modifiedKey)
  const [childData, setChildData] = useState("");

  const [position , setPosition] = useState("")

  const getValueFromChildModal = (data) => {
    setChildData(data);
  };

  const getControllerType = (fromChild) => {
    setPosition(fromChild);
  };

  const positions = ["L", "R"];

  return (
    <>
      <form onSubmit={handleSubmit}>
        <div className="main-form">
          <div className="header">
            <h2 className="main-heading">Joystick configuration</h2>
          </div>
          <div className="comands-section">
            <div className="single-div">
              <p>COMMANDS</p>
              <div className="right-items" style={{ flexDirection: "column" }}>
                <Box sx={{ ...style }}>
                  <form style={{ width: "100%" }}>
                    <div
                      className="modal-form"
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      {comands &&
                        comands.map((comand, index) => {
                          return (
                            <div
                              key={index}
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "space-between",
                                width: "100%",
                                alignItems: "center",
                              }}
                            >
                              <label htmlFor="fname">
                                Comand {index + 1} :
                              </label>
                              <p>{childData}</p>
                              <ChildModal
                                keyPressed={modifiedKey}
                                getValueFromChildModal={getValueFromChildModal}
                              />
                            </div>
                          );
                        })}
                    </div>
                  </form>
                </Box>
              </div>
            </div>
          </div>
          <div className="position-section">
            <div className="single-div">
              <p>POSITION</p>
              <div className="right-items">
                <DropDown dropdown="true" positions={positions} getControllerType={getControllerType}/>
                <FormSwitch />
              </div>
            </div>
            <div className="single-div">
              <p>HORIZONTAL AXIS</p>
              <div className="right-items">
                <DropDown inputText="Twist-dimension" dropdown="true" />
                <DropDown inputText="GamepadAxis" dropdown="false" postionValueX={modifiedAxis?.[position]?.x} />
                <DropDown inputText="Scale" dropdown="false" />
                <DropDown inputText="Exponent" dropdown="false" />
              </div>
            </div>
            <div className="single-div">
              <p>VERTICAL AXIS</p>
              <div className="right-items">
                <DropDown inputText="Twist-dimension" dropdown="true" />
                <DropDown inputText="GamepadAxis" dropdown="false" postionValueY={modifiedAxis?.[position]?.y} />
                <DropDown inputText="Scale" dropdown="false" />
                <DropDown inputText="Exponent" dropdown="false" />
              </div>
            </div>
          </div>
          <div className="position-section">
            <div className="single-div">
              <p>POSITION</p>
              <div className="right-items">
                <DropDown dropdown="true" positions={positions} getControllerType={getControllerType}/>
                <FormSwitch />
              </div>
            </div>
            <div className="single-div">
              <p>HORIZONTAL AXIS</p>
              <div className="right-items">
                <DropDown inputText="Twist-dimension" dropdown="true" />
                <DropDown inputText="GamepadAxis" dropdown="false" postionValueX={modifiedAxis?.[position]?.x} />
                <DropDown inputText="Scale" dropdown="false" />
                <DropDown inputText="Exponent" dropdown="false" />
              </div>
            </div>
            <div className="single-div">
              <p>VERTICAL AXIS</p>
              <div className="right-items">
                <DropDown inputText="Twist-dimension" dropdown="true" />
                <DropDown inputText="GamepadAxis" dropdown="false" postionValueY={modifiedAxis?.[position]?.y} />
                <DropDown inputText="Scale" dropdown="false" />
                <DropDown inputText="Exponent" dropdown="false" />
              </div>
            </div>
          </div>

          <div className="preview">
            <div className="header-1">
              <h3 className="main-heading">Preview</h3>
            </div>

            <div className="preview-section">
              <div className="left-side">
                <div className="inner-left-side">
                  <div className="left-joystick">
                    <p className="inner-main-headings">LEFT</p>
                    <div className="main-item-div">
                      <div className="preview-items-left">
                        <div className="preview-inner-item">
                          <h4 className="inner-main-subheadings">Mouse</h4>
                          <p className="inner-p">false</p>
                        </div>
                        <div className="preview-inner-item">
                          <h4 className="inner-main-subheadings">Gamepad</h4>
                          <p className="inner-p">false</p>
                        </div>
                      </div>
                      <div className="preview-items-rigth">
                        <img src={left} alt="" />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="inner-right-side">
                  <div className="left-joystick">
                    <p className="inner-main-headings">RIGHT</p>
                    <div className="main-item-div">
                      <div className="preview-items-left">
                        <div className="preview-inner-item">
                          <h4 className="inner-main-subheadings">Mouse</h4>
                          <p className="inner-p">false</p>
                        </div>
                        <div className="preview-inner-item">
                          <h4 className="inner-main-subheadings">Gamepad</h4>
                          <p className="inner-p">false</p>
                        </div>
                      </div>
                      <div className="preview-items-rigth">
                        <img src={right} alt="" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="right-side">
                <div className="right-side-inner-section">
                  <p className="inner-main-headings">OUTPUT</p>
                  <div className="right-side-items">
                    <div className="linear">
                      <h4 className="inner-main-subheadings">Linear</h4>
                      <div className="linear-items">
                        <div className="x">
                          <p className="inner-p">X:</p>
                          <p className="inner-p">0.220</p>
                        </div>
                        <div className="y">
                          <p className="inner-p">Y:</p>
                          <p className="inner-p">0.220</p>
                        </div>
                        <div className="z">
                          <p className="inner-p">Z:</p>
                          <p className="inner-p">0.220</p>
                        </div>
                      </div>
                    </div>
                    <div className="angular">
                      <h4 className="inner-main-subheadings">Angular</h4>
                      <div className="linear-items">
                        <div className="x">
                          <p className="inner-p">X:</p>
                          <p className="inner-p">0.220</p>
                        </div>
                        <div className="y">
                          <p className="inner-p">Y:</p>
                          <p className="inner-p">0.220</p>
                        </div>
                        <div className="z">
                          <p className="inner-p">Z:</p>
                          <p className="inner-p">0.220</p>
                        </div>
                      </div>
                    </div>
                    <div className="time">
                      <h4 className="inner-main-subheadings">Time</h4>
                      <p className="inner-p">7:24:12</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="bottom-options">
            <div className="single-div">
              <div
                className="left-side-text"
                style={{ display: "flex", alignItems: "center", gap: "1rem" }}
              >
                <p>Disable Gamepad Trigger Axis Mapping</p>
                <FaRegQuestionCircle />
              </div>

              <div className="right-items-bottom">
                <p>DISABLED</p>

                <FormSwitch />
              </div>
            </div>
            <div className="single-div">
              <div
                className="left-side-text"
                style={{ display: "flex", alignItems: "center", gap: "1rem" }}
              >
                <p>Disable Gamepad Input and Keyboard Input</p>
                <FaRegQuestionCircle />
              </div>
              <div className="right-items-bottom">
                <p>DISABLED</p>
                <FormSwitch />
              </div>
            </div>
          </div>
        </div>
      </form>
    </>
  );
};

export default Form;
