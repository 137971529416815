import Home from "./home/Home";
import ControlScreen from "./control-page/ControlScreen";
import Config from "./config/Config";

const pages = {
  Home,
  ControlScreen,
  Config
};

export default pages;
