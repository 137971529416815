import React, { useEffect } from "react";
import "./App.css";
import pages from "./pages";
import { Route, Routes } from "react-router-dom";
import { MainStream } from "./components/cameras/main";

function App() {
  useEffect(() => {
    MainStream();
  }, []);

  return (
    <div className="App">
      <div className="main-app">
        <Routes>
          <Route path="/" element={<pages.Home />} />
          <Route path="/control/:id" element={<pages.ControlScreen />} />
          <Route path="/control/:id/config" element={<pages.Config />} />
        </Routes>
      </div>
    </div>
  );
}

export default App;
