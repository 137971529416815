import { useEffect, useState } from "react";
import {
  InputLabel,
  MenuItem,
  FormControl,
  Select,
  Box,
  TextField,
} from "@mui/material";
import { ThemeProvider, createTheme } from '@mui/material/styles';
import "./Dropdown.css"

const theme = createTheme({
  status: {
    danger: '#e53e3e',
  },
  palette: {
    primary: {
      main: '#ffffff',
      darker: '#053e85',
    },
    neutral: {
      main: '#64748B',
      contrastText: '#fff',
    },
  },
});

export const DropDown = (props) => {
  const [state, setState] = useState("");

  const handleChange = (event) => {
    setState(event.target.value);
    props.getControllerType(event.target.value)
  };




let comands = props.comands
let postions = props.positions
let positionValueX = props.postionValueX
let positionValueY = props.postionValueY

console.log(positionValueX)
  return (
    <>
    <Box sx={{ minWidth: 120, width: "100%" }}>
    <ThemeProvider theme={theme}>
      <FormControl fullWidth>
      {props.dropdown === "false" ? null : (
        <InputLabel className="input-mui" color="primary" id="demo-simple-select-label">
          {props.inputText}
        </InputLabel>)}
        {props.dropdown === "false" ? (
          
          <TextField id="outlined-basic" defaultValue={0} label={props.inputText} sx={{ color: "white", background: "#2b2a44" }} value={positionValueX ? positionValueX : positionValueY}/>
        ) : (
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={state}
            label={props.inputText ? props.inputText : ""}
            onChange={handleChange}
            sx={{ color: "white", background: "#2b2a44" }}
          >

          {comands && comands.map((values,index)=>{
            return(
            <MenuItem key={index} value={values}>{values}</MenuItem>
            )
          })}

          {postions && postions.map((values,index)=>{
            return(
              <MenuItem key={index} value={values}>{values}</MenuItem>
            )
          })

          }
         
          </Select>
        )}
      </FormControl>
      </ThemeProvider>
    </Box>
    </>
  );
};
