import { resourceUsage } from "process";
import { robotsConstants } from "../../constants/robots/robot";
import { mean } from "lodash";
import { IoMdPhoneLandscape } from "react-icons/io";

export const robotDetailsReducer = (state = { robot: {} }, action: any) => {
  switch (action.type) {
    case robotsConstants.ROBOTS_DETAILS_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case robotsConstants.ROBOTS_DETAILS_SUCCESS:
      return {
        loading: false,
        robot: action.payload,
      };

    case robotsConstants.ROBOTS_DETAILS_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case robotsConstants.CLEAR_ERRORS: {
      return {
        ...state,
        error: null,
      };
    }

    default:
      return state;
  }
};

export const robotCommandsReducer = (state = {}, action: any) => {
  switch (action.type) {
    case robotsConstants.ROBOT_HEAD_LIGHT_REQUEST:
    case robotsConstants.ROBOT_RING_LIGHT_REQUEST:
    case robotsConstants.ROBOT_STREAM_CLIENT_REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }

    case robotsConstants.ROBOT_HEAD_LIGHT_SUCCESS:
    case robotsConstants.ROBOT_RING_LIGHT_SUCCESS:
    case robotsConstants.ROBOT_STREAM_CLIENT_SUCCESS: {
      return {
        ...state,
        loading: false,
        success: action.payload,
      };
    }

    case robotsConstants.ROBOT_HEAD_LIGHT_FAIL:
    case robotsConstants.ROBOT_RING_LIGHT_FAIL:
    case robotsConstants.ROBOT_STREAM_CLIENT_FAIL: {
      return {
        ...state,
        loading: true,
        error: action.payload,
      };
    }

    case robotsConstants.CLEAR_ERRORS: {
      return {
        ...state,
        error: null,
      };
    }

    default:
      return state;
  }
};


export const xboxReducer = (state = { controllerCommands: {} }, action: any) => {
  switch (action.type) {
    case robotsConstants.UPDATE_MODIFIED_KEY:
      return {
        modifiedKey: action.payload,
      };
     case robotsConstants.UPDATE_MODIFIED_AXIS:
      return {
        modifiedAxis : action.payload,
      };
    default:
      return state;
  }
}


