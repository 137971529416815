import { useMediaQuery } from "react-responsive";
import { NavLink } from "react-router-dom";

const CardItems = ({ cardValues, svgs, components, allIcons }: any) => {
  const mQ = useMediaQuery({ query: "(max-width : 976px)" });
  return (
    <>
      <div className="card-items">
        {cardValues.innerCarddata &&
          cardValues.innerCarddata.map((c: any, index: any) => {
            return (
              <div key={"card__item__" + index}>
                <div className="inner-card-items">
                  <div className="card-item-left">
                    <div
                      className="card-item-1"
                      style={{
                        paddingBottom: mQ && c.teleOpACK ? "22px" : "0px",
                      }}
                    >
                      <NavLink
                        to={`/control/${c.robotID}`}
                        style={{
                          textDecoration: "none",
                          margin: "0px",
                          color: c.teleOpACK ? "#e96c67" : "#FFFFFF",
                        }}
                      >
                        {c.robotID}
                      </NavLink>

                      {c.teleOpACK ? (
                        <p className="teleop-p" style={{ margin: "0px" }}>
                          Teleop_ACK_Requested
                        </p>
                      ) : null}
                    </div>
                    <div
                      className="card-item-2"
                      style={{
                        color: c.status ? "#71cf62" : "#EA6161",
                      }}
                    >
                      <p style={{ margin: "0px" }}>
                        {c.status ? "Available" : "Unavailable"}
                      </p>
                    </div>
                    {c.status ? (
                      <div
                        className="card-item-3"
                        style={{
                          opacity: c.controllerStatus ? "1" : "0.2",
                        }}
                      >
                        <img
                          src={svgs.controller}
                          alt="controller"
                          className="controller"
                        />
                        <p
                          style={{
                            margin: "0px",
                            fontSize: "10px",
                            fontWeight: "500",
                          }}
                        >
                          {c.controllerStatus ? "ON" : "OFF"}
                        </p>
                      </div>
                    ) : null}
                  </div>
                  <div className="card-item-right">
                    <div className="card-item-right-1">
                      <div className="battery-icon">
                        <components.Battery perc={c.batteryPercentage} />

                        <p
                          style={{
                            margin: "0px",
                            fontSize: "8px",
                            fontWeight: "600",
                          }}
                        >
                          {c.batteryPercentage}%
                        </p>
                      </div>
                      <div className="signal-icon">
                        <allIcons.FontAwesomeIcon icon={allIcons.faSignal} />
                      </div>
                      <div className="play-pause-icon">
                        <allIcons.FontAwesomeIcon
                          icon={allIcons.faCirclePause}
                        />
                      </div>
                    </div>
                    <div className="card-item-right-2">
                      <div className="circle-icons">
                        {c.status &&
                          c.operators.map((u: any, index: any) => {
                            return (
                              <div
                                className="circle-icon"
                                key={"circle__icon__" + index}
                              >
                                <components.CustomCircle value={u} />
                              </div>
                            );
                          })}
                      </div>
                    </div>
                  </div>
                </div>

                {index !== cardValues.innerCarddata.length - 1 ? (
                  <div className="card-white-line"></div>
                ) : null}
              </div>
            );
          })}
      </div>
    </>
  );
};

export default CardItems;
