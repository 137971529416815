
const CardOuterCom = ({svgs,cardValues}:any) => {
  return (
    <> <div className="card-outer-items">
    <div className="card-outer-left">
      <img src={svgs.location} alt="location" />
      <h3 className="location-h3">{cardValues.location}</h3>
    </div>
    <div className="card-outer-right">
      <h2 className="card-total-robots">
        {cardValues.noOfRobots()}
      </h2>
      <p style={{ margin: "0px" }}>robots</p>
    </div>
  </div></>
  )
}

export default CardOuterCom