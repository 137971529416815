import { useState, useEffect } from "react";
import { IconContext } from "react-icons";
import { allIcons } from "../../assets/icons";
import { svgs } from "../../assets/svgs";
import { components } from "../index";
import "./SidebarTeleop.css";
import { useCurrentUser } from "../../hooks/Hooks";

const SidebarTeleop = ({ robotData, unlockController, sidebar , lock}: any) => {
  
  let {curUser, currentUser  } = useCurrentUser(robotData);

 //Unlock controller
 const [unlockChecked, setUnlockChecked] = useState(false);

 const handleUnlockSwitchClick = () => {
   const newValue = !unlockChecked;
   setUnlockChecked(!unlockChecked);
   unlockController(newValue);
   lock(newValue);
 };


  useEffect(() => {
    currentUser();
  }, [curUser]);

  return (
    <IconContext.Provider value={{ color: "#fff" }}>
      <aside
        style={sidebar ? { opacity: "1" } : { opacity: "0" }}
        // className="robot__teleop__details"
        className="teleop__details"
      >
        <div className="robot__teleop__details__header">
          <div
            className="robot__teleop"
            style={{ width: robotData.teleOpACK ? "69%" : "100%" }}
          >
            TELE OP MOVING
          </div>
          {robotData.teleOpACK ? (
            <div className="robot__teleop__ack">TELEOP ACK</div>
          ) : null}
        </div>
        <span className="robot__teleop__location">MOVELOCATION</span>
        <span className="robot__order__id">Order Id : {robotData.orderId}</span>
        {/* </div> */}
        <div className="robot__actions">
          <span className="robot__action"> Finish Action</span>
          <span className="teleop__watching">
            {" "}
            <allIcons.AiIcons.AiFillEye className="watching__icon" />
            <strong>
              {robotData?.available ? robotData?.operators.length : 0}
            </strong>{" "}
            Watching
          </span>
        </div>
        <div className="users__info__panel">
          <div className="users__info">
            <div className="game__controller__status">
              <img src={svgs.controller} alt="controller svg" />
              <span className="controller__status">
                {robotData.controllerStatus ? "ON" : "OFF"}
              </span>
            </div>
            <div className="current__user">{curUser}</div>
            <div className="circle__icons">
              {robotData.available &&
                robotData.operators.map((x: any, index: any) => {
                  return (
                    <div
                      className="circle__icon"
                      key={"circle__icon__" + index}
                    >
                      <components.CustomCircle value={x} />
                    </div>
                  );
                })}
            </div>
          </div>
          <div onClick={handleUnlockSwitchClick} className="unlock__btn">
            {unlockChecked ? "Lock Joystick" : "Unlock Joystick"}
          </div>
        </div>
      </aside>
    </IconContext.Provider>
  );
};

export default SidebarTeleop;
