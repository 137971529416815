import { ReactNode, useEffect, useState } from "react";
import { QueryModule } from "joymap";
import { map } from "lodash/fp";
import { images } from "../../assets/images";

import { ReactInputs, Back, inputs } from "./styles";
import { updateModifiedKey, updateModifiedAxis } from "../../actions/robots/robot";
import { useAppDispatch } from "../../hooks/Hooks";

type InputNames = keyof typeof inputs;



const analogInputs: InputNames[] = ["L", "R"];
const shoulderInputs: InputNames[] = ["L2", "L1", "R2", "R1"];
const digitalInputs: InputNames[] = [
  "dpadUp",
  "dpadDown",
  "dpadLeft",
  "dpadRight",
  "A",
  "B",
  "X",
  "Y",
  "start",
  "select",
  "home",
];

interface GamepadProps {
  backgroundColor: string;
  pressedColor: string;
  module: QueryModule;
  name: string;
  children: ReactNode;
}

interface ButtonProps {
  inputName: keyof typeof inputs;
  pressedColor: string;
  module: QueryModule;
  setWaitingFor: (value: string | null) => void;
  setAllButtons: any;
  allButtons: any;
}

function Stick({
  inputName,
  pressedColor,
  module,
  setWaitingFor,
  setAllButtons,
  allButtons,
}: ButtonProps) {
  const [x, y] = module.getStick(inputName).value;
  const { pressed } = module.getButton(`${inputName}3`);
  const StickComponent = inputs[inputName];

  useEffect(() => {
    setAllButtons((allButtons: any) => ({
      ...allButtons,
      axis: {
        ...allButtons.axis,
        [inputName]: {
          ...allButtons.axis[inputName],
          x: x,
          y: y === -1 ? Math.abs(y) : -Math.abs(y),           //inverted y's value
        },
      },
    }));


  }, [x, y]);

  useEffect(() => {
    setAllButtons({
      ...allButtons,
      buttons: { ...allButtons.buttons, [`${inputName}3`]: +pressed },
    });
  }, [pressed]);

  return (
    <StickComponent
      onClick={() => {
        if (module.isConnected()) {
          module.stickBindOnPress(inputName, () => setWaitingFor(null));
          setWaitingFor(inputName);
        }
      }}
      style={{
        transform: `translate(${x * 35}px, ${y * 35}px)`,
        backgroundColor: pressed ? pressedColor : "",
      }}
    />
  );
}

function Digital({
  inputName,
  pressedColor,
  module,
  setWaitingFor,
  setAllButtons,
  allButtons,
}: ButtonProps) {
  const { pressed } = module.getButton(inputName);
  const DigitalComponent = inputs[inputName];

  useEffect(() => {
    setAllButtons({
      ...allButtons,
      buttons: { ...allButtons.buttons, [inputName]: +pressed },
    });
  }, [pressed]);

  return (
    <DigitalComponent
      style={{ backgroundColor: pressed ? pressedColor : "" }}
      onClick={() => {
        if (module.isConnected()) {
          module.buttonBindOnPress(inputName, () => setWaitingFor(null));
          setWaitingFor(inputName);
        }
      }}
    />
  );
}

function Shoulder({
  inputName,
  module,
  allButtons,
  setAllButtons,
}: {
  inputName: InputNames;
  module: QueryModule;
  allButtons: any;
  setAllButtons: any;
}) {
  const { value } = module.getButton(inputName);
  const ShoulderComponent = inputs[inputName];

  useEffect(() => {
    setAllButtons({
      ...allButtons,
      buttons: { ...allButtons.buttons, [inputName]: value },
    });
  }, [value]);

  return (
    <div
      style={{
        position: "fixed",
        transform: `translateY(${value * 10}px)`,
      }}
    >
      <ShoulderComponent />
    </div>
  );
}

export default function Gamepad({ pressedColor, module, name }: GamepadProps) {
  const [waitingFor, setWaitingFor] = useState<string | null>(null);
  type Buttons = {
    [key: string]: number;
  };

  const [allButtons, setAllButtons] = useState<{ buttons: Buttons; axis: any }>(
    {
      buttons: {
        L1: 0,
        L2: 0,
        L3: 0,
        R1: 0,
        R2: 0,
        R3: 0,
        dpadUp: 0,
        dpadDown: 0,
        dpadLeft: 0,
        dpadRight: 0,
        A: 0,
        B: 0,
        X: 0,
        Y: 0,
        start: 0,
        select: 0,
        home: 0,
      },
      axis: {
        L: { x: 0, y: 0 },
        R: { x: 0, y: 0 },
      },
    }
  );
  const [modifiedKey, setModifiedKey] = useState("");


  const dispatch = useAppDispatch();
  useEffect(() => {
    for (let i in allButtons.buttons) {
      if (allButtons.buttons[i] === 1) {               //whenever the value of any button changes from 0 to 1, it will so some stuff
        // console.log(allButtons.buttons[i])
        setModifiedKey(i);
        dispatch(updateModifiedKey(i));
      }
    }

    for(let i in allButtons.axis){
      for(let j in allButtons.axis[i]){
        if (allButtons.axis[i][j]) {           //Need to work on this 
        //  console.log(allButtons.axis[i][j])          
          setModifiedKey(i);
          dispatch(updateModifiedAxis(allButtons.axis))
        } 
      }
    }
// console.log(modifiedKey)
   
  }, [allButtons, dispatch]);

  const comands = [
    "L1",
    "L2",
    "L3",
    "R1",
    "R2",
    "R3",
    "dpadUp",
    "dpadDown",
    "dpadLeft",
    "dpadRight",
    "A",
    "B",
    "X",
    "Y",
    "start",
    "select",
  ];

  const controllerType = ["Keyboard", "PS-Controller", "Xbox-Controller"];

  return (
    <>
      <div
        className="main-config-joystick"
        
      >
        <ReactInputs>


          {modifiedKey ? (
            <>
              {" "}
              <div
                className="pressed-key"
                style={{
                  border: "2px solid #7def7d",
                  borderRadius: "3px",
                  padding: "15px",
                  position: "fixed",
                  marginBottom: "36rem",
                }}
              >
                <h1 className="pressed-key-h1" style={{ fontSize: "23px" }}>
                  {modifiedKey === "L" || modifiedKey === "R" ? `You Moved ${modifiedKey}` : `You Pressed ${modifiedKey}`}
                </h1>
              </div>
             
            </>
          ) : null}
          <Back
            style={{
              backgroundImage: `url(${images.xbox})`,
            }}
          />
          {map(
            (inputName) => (
              <Shoulder
                key={inputName}
                inputName={inputName}
                module={module}
                setAllButtons={setAllButtons}
                allButtons={allButtons}
              />
            ),
            shoulderInputs
          )}
          {map(
            (inputName) => (
              <Stick
                key={inputName}
                inputName={inputName}
                pressedColor={pressedColor}
                module={module}
                setWaitingFor={setWaitingFor}
                setAllButtons={setAllButtons}
                allButtons={allButtons}
              />
            ),
            analogInputs
          )}
          {map(
            (inputName) => (
              <Digital
                key={inputName}
                inputName={inputName}
                pressedColor={pressedColor}
                module={module}
                setWaitingFor={setWaitingFor}
                setAllButtons={setAllButtons}
                allButtons={allButtons}
              />
            ),
            digitalInputs
          )}
        </ReactInputs>
      </div>
    </>
  );
}
