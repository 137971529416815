import "./Battery.css";

const Battery = (props: any) => {
  return (
    <div className="cus-battery-icon" style={props.controlPageStylesBattery}>
      <div
        className="cus-inner-battery"
        style={{
          width: `${props.perc}%`,
          backgroundColor: props.perc < 20 ? "red" : "#88cd6f",
        }}
      ></div>
      <div
        className="cus-battery-part"
        style={props.controlPageStylesOuterPart}
      ></div>
    </div>
  );
};

export default Battery;
