import "./Navbar.css";
import logo from "../../assets/images/Image 1.png";
import { svgs } from "../../assets/svgs";
import { useMediaQuery } from "react-responsive";
import Dropdown from "../dropdown/Dropdown";
import { components } from "../index"

const Navbar = () => {
  const mQ = useMediaQuery({ query: "(max-width : 500px)" });
  return (
    <>
      <div className="nav-main">
        <div className="nav-items">
          <div className="nav-left">
            <div className="nav-logo">
              <img src={logo} alt="Ottonomy.io" />
            </div>
          </div>
          {mQ ? (
            <div className="nav-menu" >
              <components.Dropdown />
            </div>
          ) : (
            <div className="nav-right">
              <div className="nav-right-1">
                <div className="nav-right-1-logo">
                  <img src={svgs.user} alt="user" />
                </div>
                <div className="nav-right-1-info">
                  <p className="user-name">Aakash</p>
                  <p className="user-email">aakash.dammala@ottonomy.io</p>
                </div>
              </div>
              <div className="white-line"></div>
              <div className="nav-right-2-logo">
                <img src={svgs.Login} alt=""></img>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default Navbar;
