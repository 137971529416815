import React, { useEffect, useRef } from "react";
import { BsChevronLeft, BsThreeDotsVertical } from "react-icons/bs";
import { NavLink, useNavigate } from "react-router-dom";
import { styles } from "../../custom styles";
import "./RobotStatusBar.css";
import { Switch } from "antd";

import {
  Stack,
  Button,
  ClickAwayListener,
  Grow,
  Paper,
  Popper,
  MenuItem,
  MenuList,
} from "@mui/material";
import { components } from "../../components/index";
import { useLights } from "../../hooks/Hooks";

const RobotStatusBar = ({ robotData }: any) => {
  const {
    ringLightChecked,
    handleRingLight,
    headLightChecked,
    handleHeadLight,
    streamClientChecked,
    handleStreamClient,
  } = useLights();

  const [open, setOpen] = React.useState(false);
  const anchorRef = useRef<HTMLButtonElement>(null);

  const navigate = useNavigate();

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: Event | React.SyntheticEvent) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return;
    }

    setOpen(false);
  };

  function handleListKeyDown(event: React.KeyboardEvent) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen(false);
    } else if (event.key === "Escape") {
      setOpen(false);
    }
  }

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = useRef(open);
  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current!.focus();
    }

    prevOpen.current = open;
  }, [open]);

  const { controlPageStylesBattery, controlPageStylesOuterPart } = styles();

  const changeTimeZone = (date: any, timeZone: any) => {
    if (typeof date === "string") {
      return new Date(
        new Date(date).toLocaleString("en-US", {
          timeZone,
        })
      );
    }
    return new Date(
      date.toLocaleString("en-US", {
        timeZone,
      })
    );
  };

  return (
    <header>
      <nav>
        <div className="nav__left">
          <NavLink to="/" className="back__btn">
            <BsChevronLeft fontSize={"28px"} className="back__btn__icon" />
          </NavLink>
          <div className="robot__detail">
            <div className="robot__id__container">
              <span className="robot__id"> {robotData.robotID} </span>
              <span className="robot__date">
                {" "}
                {changeTimeZone(
                  new Date(),
                  robotData.timezone
                ).toUTCString()}{" "}
              </span>
            </div>
            <div className="robot__battery">
              <div
                className="robot__status"
                style={{
                  color: robotData.available ? "rgb(113, 207, 98)" : "#e96c67",
                }}
              >
                {robotData.available ? "ONLINE" : "OFFLINE"}
              </div>
              <div className="robot__battery__details">
                <div className="robot__battery__percentage inner-stuff">
                  {robotData.batteryPercentage}%
                </div>
                <div className="robot__battery__logo">
                  <components.Battery
                    perc={robotData.batteryPercentage}
                    controlPageStylesBattery={controlPageStylesBattery}
                    controlPageStylesOuterPart={controlPageStylesOuterPart}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="nav__center">
          <div className="robot__speed__details">
            <div className="robot__speed__text">SPEED</div>
            <div className="robot__speed">{robotData.speed} m/sec</div>
          </div>
          <div className="robot__ring__light__details">
            <div className="robot__ring__light__text">Ring Light</div>
            <div className="robot__ring__light__switch">
              <Switch
                className="robot__ring__light__switch__main"
                onChange={handleRingLight}
                checked={ringLightChecked}
              />
            </div>
          </div>
          <div className="robot__head__light__details">
            <div className="robot__head__light__text">Head Light</div>
            <div className="robot__head__light__switch">
              <Switch
                className="robot__head__light__switch__main"
                onChange={handleHeadLight}
                checked={headLightChecked}
              />
            </div>
          </div>
          <div className="robot__stream__client__details">
            <div className="robot__stream__client__text">Stream Client</div>
            <div className="robot__stream__client__switch">
              <Switch
                className="robot__stream__client__switch__main"
                onChange={handleStreamClient}
                checked={streamClientChecked}
              />
            </div>
          </div>
          <div className="robot__total__distance__details">
            <div className="robot__total__distance__text">
              Total Distance Covered
            </div>
            <div className="robot__total__distance">
              {" "}
              {robotData.totalDistance / 1000} Km
            </div>
          </div>
        </div>
        <div className="nav__right">
          <span className="reset__diagnostics__btn">Reset Diagnostics</span>
          <span className="robot__e__stop">E-STOP</span>
          <Button
            ref={anchorRef}
            id="composition-button"
            aria-controls={open ? "composition-menu" : undefined}
            aria-expanded={open ? "true" : undefined}
            aria-haspopup="true"
            onClick={handleToggle}
            style={{
              minWidth: "10px",
            }}
          >
            <span className="robot__expandable__options">
              <BsThreeDotsVertical />
            </span>
          </Button>
        </div>
      </nav>
      <Stack direction="row" spacing={2}>
        <Popper
          open={open}
          anchorEl={anchorRef.current}
          role={undefined}
          placement="bottom-start"
          transition
          disablePortal
          style={{
            zIndex: "99",
          }}
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin:
                  placement === "bottom-start" ? "left top" : "left bottom",
              }}
            >
              <Paper
                style={{
                  background: "#1C1E2B",
                  borderRadius: "7px",
                  color: "white",
                  padding: "0",
                  marginTop: "15px",
                }}
              >
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList
                    autoFocusItem={open}
                    id="composition-menu"
                    aria-labelledby="composition-button"
                    onKeyDown={handleListKeyDown}
                    style={{
                      padding: "0",
                    }}
                  >
                    <MenuItem
                      onClick={handleClose}
                      className="menu__extra__options"
                    >
                      Disengage Motors
                    </MenuItem>
                    <MenuItem
                      onClick={handleClose}
                      className="menu__extra__options"
                    >
                      Send Home
                    </MenuItem>
                    <MenuItem
                      onClick={handleClose}
                      className="menu__extra__options"
                    >
                      Open SSH
                    </MenuItem>
                    <MenuItem
                      onClick={handleClose}
                      className="menu__extra__options nav__right__extra__options"
                    >
                      Reset Diagnostics
                    </MenuItem>
                    <MenuItem
                      onClick={handleClose}
                      className="menu__extra__options"
                    >
                      Directions
                    </MenuItem>
                    <MenuItem
                      onClick={() => navigate(`/control/${robotData.robotID}/config`)}
                      className="menu__extra__options"
                    >
                      Configure Controller
                    </MenuItem>
                    <MenuItem
                      onClick={handleClose}
                      className="menu__extra__options nav__right__extra__options"
                    >
                      E-STOP
                    </MenuItem>
                    <MenuItem
                      onClick={handleClose}
                      className="menu__extra__options"
                    >
                      Logout
                    </MenuItem>
                    <MenuItem
                      onClick={handleRingLight}
                      className="menu__extra__options menu__extra__responsive"
                    >
                      <span>Ring Light</span>
                      <Switch
                        className="robot__ring__light__switch__main"
                        onChange={handleRingLight}
                        checked={ringLightChecked}
                      />
                    </MenuItem>
                    <MenuItem
                      onClick={handleHeadLight}
                      className="menu__extra__options menu__extra__responsive"
                    >
                      <span>Head Light</span>
                      <Switch
                        className="robot__head__light__switch__main"
                        onChange={handleHeadLight}
                        checked={headLightChecked}
                      />
                    </MenuItem>
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </Stack>
    </header>
  );
};

export default RobotStatusBar;
