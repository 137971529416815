import "./StreamDeck.css";

const StreamDeck = ({ robotData }: any) => {
  return (
    <>
      <section className="video__stream">
        <video
          className="cam__video"
          id="stream"
          src={robotData.url}
          autoPlay
          muted
          loop
          controls={false}
        />
        {/* <div className="robot__controls__container">
          <div className="unlock__robot__controls__container">
          <span className="unlock__robot__controls">
            <Switch
              className="robot__unlock__switch__main"
              onChange={handleSwitchClick}
              checked={checked}
            />
          </span>
          <span className="unlock__robot__controls__text">Unlock</span>
          </div>
        </div> */}
      </section>
    </>
  );
};

export default StreamDeck;
