import React, { useState } from "react";
import "./Circle.css";

const CustomCircle = (props: any) => {
  return (
    <>
      <div
        className="main-circle hover-text"
        style={{ background: props.value.user ? "#71cf62" : "#bec4de" }}
      >
        <h1 className="inner-text">{props.value.shortName}</h1>

        <span className="tooltip" id="bottom">
          {props.value.userName}
        </span>
      </div>
    </>
  );
};

export default CustomCircle;
