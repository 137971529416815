import * as React from "react";
import { Switch } from "@mui/material";

const label = { inputProps: { "aria-label": "Switch demo" } };

export const FormSwitch = () => {
  return (
    <div>
      <Switch {...label} />
    </div>
  );
};
