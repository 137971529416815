import { useState } from "react";
import "./Home.css";
import { cardData } from "../../assets/other-utils";
import { components } from "../../components";

const Home = () => {
  const [data, setData] = useState(cardData);
  return (
    <>
      <div className="home-nav">
        <components.Navbar />
      </div>

      <div className="home-main">
        {data &&
          data.map((x, index) => {
            return (
              <div key={"card__" + index}>
                <components.Cards cardValues={x} />
              </div>
            );
          })}
      </div>
    </>
  );
};

export default Home;
