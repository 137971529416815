export const robotsConstants = {
  ROBOTS_DETAILS_REQUEST: "ROBOTS_DETAILS_REQUEST",
  ROBOTS_DETAILS_SUCCESS: "ROBOTS_DETAILS_SUCCESS",
  ROBOTS_DETAILS_FAIL: "ROBOTS_DETAILS_FAIL",

  ROBOT_HEAD_LIGHT_REQUEST: "ROBOT_HEAD_LIGHT_REQUEST",
  ROBOT_HEAD_LIGHT_SUCCESS: "ROBOT_HEAD_LIGHT_SUCCESS",
  ROBOT_HEAD_LIGHT_FAIL: "ROBOT_HEAD_LIGHT_FAIL",

  ROBOT_RING_LIGHT_REQUEST: "ROBOT_RING_LIGHT_REQUEST",
  ROBOT_RING_LIGHT_SUCCESS: "ROBOT_RING_LIGHT_SUCCESS",
  ROBOT_RING_LIGHT_FAIL: "ROBOT_RING_LIGHT_FAIL",

  ROBOT_STREAM_CLIENT_REQUEST: "ROBOT_STREAM_CLIENT_REQUEST",
  ROBOT_STREAM_CLIENT_SUCCESS: "ROBOT_STREAM_CLIENT_SUCCESS",
  ROBOT_STREAM_CLIENT_FAIL: "ROBOT_STREAM_CLIENT_FAIL",

  UPDATE_MODIFIED_KEY : "UPDATE_MODIFIED_KEY",
  UPDATE_MODIFIED_AXIS : "UPDATE_MODIFIED_AXIS",
  

  CLEAR_ERRORS: "CLEAR_ERRORS",
};
