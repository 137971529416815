export const cardData = [
  {
    location: "Bradley",
    noOfRobots: function len() {
      return this.innerCarddata.length;
    },
    innerCarddata: [
      // {
      //   robotID: "OB02221013",
      //   totalDistance : 357340,
      //   speed: 1.05, 
      //   status: true,
      //   controllerStatus: true,
      //   batteryPercentage: 100,
      //   signal: "",
      //   available: true,
      //   location: "",
      //   teleOpACK: true,
      //   playPause: "",
      //   timezone: "IST",
      //   orderId: "Ord3456721",
      //   // url: "https://director.millicast.com/api/whep/rGE9Jy/OB02221020",
      //   operators: [
      //     {
      //       userName: "Hardik Sharma",
      //       shortName: "HS",
      //       user: true,
      //     },
      //     {
      //       userName: "John Doe",
      //       shortName: "JD",
      //       user: false,
      //     },
      //   ],
      // },
      // {
      //   robotID: "OB02221014",
      //   totalDistance : 357340,
      //   speed: 1.05, 
      //   status: true,
      //   controllerStatus: false,
      //   batteryPercentage: 42,
      //   signal: "",
      //   available: true,
      //   location: "",
      //   teleOpACK: false,
      //   playPause: "",
      //   timezone: "EST",
      //   // url: "https://director.millicast.com/api/whep/rGE9Jy/OB02221020",
      //   operators: [
      //     {
      //       userName: "Hardik Sharma",
      //       shortName: "HS",
      //       user: false,
      //     },
      //     {
      //       userName: "John Doe",
      //       shortName: "JD",
      //       user: false,
      //     },
      //     {
      //       userName: "Jacob Frye",
      //       shortName: "JF",
      //       user: true,
      //     },
      //   ],
      // },
      {
        robotID: "OB02221018",
        totalDistance : 357340,
        speed: 1.05,
        status: true,
        controllerStatus: true,
        batteryPercentage: 78,
        signal: "",
        available: true,
        location: "",
        teleOpACK: false,
        playPause: "",
        timezone: "IST",
        // url: "https://director.millicast.com/api/whep/rGE9Jy/OB02221018",
        operators: [
          {
            userName: "Hardik Sharma",
            shortName: "HS",
            user: false,
          },
          {
            userName: "John Doe",
            shortName: "JD",
            user: true,
          },
          {
            userName: "Jacob Frye",
            shortName: "JF",
            user: false,
          },
        ],
      },
      // {
      //   robotID: "OB02221016",
      //   totalDistance : 357340,
      //   speed: 1.05, 
      //   status: false,
      //   controllerStatus: true,
      //   batteryPercentage: 12,
      //   signal: "",
      //   available: true,
      //   location: "",
      //   teleOpACK: false,
      //   playPause: "",
      //   timezone: "IST",
      //   // url: "https://director.millicast.com/api/whep/rGE9Jy/OB02221020",
      //   operators: [
      //     {
      //       userName: "Hardik Sharma",
      //       shortName: "HS",
      //       user: false,
      //     },
      //     {
      //       userName: "John Doe",
      //       shortName: "JD",
      //       user: true,
      //     },
      //     {
      //       userName: "Jacob Frye",
      //       shortName: "JF",
      //       user: false,
      //     },
      //   ],
      // },
      // {
      //   robotID: "OB02221017",
      //   totalDistance : 357340,
      //   speed: 1.05, 
      //   status: false,
      //   controllerStatus: true,
      //   batteryPercentage: 100,
      //   signal: "",
      //   available: true,
      //   location: "",
      //   teleOpACK: false,
      //   playPause: "",
      //   timezone: "IST",
      //   orderId: "Ord3456721",
      //   // url: "https://director.millicast.com/api/whep/rGE9Jy/OB02221020",
      //   operators: [],
      // },
    ],
  },
  // {
  //   location: "Alcobendas",
  //   noOfRobots: function len() {
  //     return this.innerCarddata.length;
  //   },
  //   innerCarddata: [
  //     {
  //       robotID: "OB02221010",
  //       totalDistance : 357340,
  //       speed: 1.05, 
  //       status: true,
  //       controllerStatus: true,
  //       batteryPercentage: 100,
  //       signal: "",
  //       available: true,
  //       location: "",
  //       teleOpACK: true,
  //       playPause: "",
  //       timezone: "IST",
  //       orderId: "Ord3456721",
  //       // url: "https://director.millicast.com/api/whep/rGE9Jy/OB02221020",
  //       operators: [
  //         {
  //           userName: "Hardik Sharma",
  //           shortName: "HS",
  //           user: false,
  //         },
  //         {
  //           userName: "John Doe",
  //           shortName: "JD",
  //           user: true,
  //         },
  //       ],
  //     },
  //     {
  //       robotID: "OB02221012",
  //       totalDistance : 357340,
  //       speed: 1.05, 
  //       status: true,
  //       controllerStatus: false,
  //       batteryPercentage: 42,
  //       signal: "",
  //       available: true,
  //       location: "",
  //       teleOpACK: false,
  //       playPause: "",
  //       timezone: "IST",
  //       orderId: "Ord3456721",
  //       // url: "https://director.millicast.com/api/whep/rGE9Jy/OB02221020",
  //       operators: [
  //         {
  //           userName: "John Doe",
  //           shortName: "JD",
  //           user: true,
  //         },
  //         {
  //           userName: "Jacob Frye",
  //           shortName: "JF",
  //           user: false,
  //         },
  //       ]
  //     },
  //   ],
  // },
];

export const statusBarData = {
  totalWatching: function usersLen() {
    return this.totalUsers.length;
  },
  controllerStatus: true,
  currentUser: "Janmejay",
  totalUsers: [
    {
      userName: "Hardik Sharma",
      shortName: "HS",
      user: false,
    },
    {
      userName: "John Doe",
      shortName: "JD",
      user: true,
    },
    {
      userName: "Jacob Frye",
      shortName: "JF",
      user: false,
    },
  ],
  batteryPercentage: 23,
  betteryRemainingTime: "3hr 45min remaining",
  speed: 0.8,
  weather: { temp: 22, condition: "Haze" },
  time: function () {
    const d = new Date();
    let ampm = d.getHours() >= 12 ? "PM" : "AM";
    return d.getHours() + ":" + d.getMinutes() + " " + ampm;
  },
  unlock: true,
};
